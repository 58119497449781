import { PartnerSettings } from '../types/PartnerSettings';

const partnerSettings: Record<
  string,
  Omit<Partial<PartnerSettings>, 'theme'> & { theme: Record<string, string> }
> = {
  'barclaycard.uk': {
    id: 'Barclaycard',
    displayName: 'Barclaycard',
    theme: {
      primary: '40 92 164',
      secondary: '162 48 116',
    },
    images: {
      logo: 'Barclaycard/logo.svg',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/Barclaycard/favicon.ico',
    },
    paymentChannels: [
      {
        name: 'Barclaycard',
        type: 'Card Processor',
        primary: true,
        existing_split: false,
      },
    ],
    activeCustomerSettings: {
      AUTH0_BASE_URL: 'https://barclaycard.uk.liberis.com',
    },
    activeCustomerOnly: true,
  },
  clearcourse: {
    id: 'CLEARCOURSE_GBR',
    displayName: 'Clear Course',
    theme: {
      primary: '119 179 42',
      secondary: '51 46 72',
      checkboxColour: 'var(--secondary)',
    },
    images: {
      logo: 'CLEARCOURSE_GBR/logo.svg',
      heroImage: 'CLEARCOURSE_GBR/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/891813799?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      useCases1: 'CLEARCOURSE_GBR/growth.png',
      useCases2: 'CLEARCOURSE_GBR/cashflow.png',
      aboutLiberisCrossbrand: 'CLEARCOURSE_GBR/aboutLiberisCrossbrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/CLEARCOURSE_GBR/favicon.ico',
    },
    allowIndicativeFallback: true,
  },
  'cardnet.uk': {
    id: 'Cardnet',
    displayName: 'Cardnet',
    theme: {
      primary: '35 44 47',
      'core-8': '80 86 89',
    },
    images: {
      logo: 'Cardnet/logo.svg',
      heroImage: 'Cardnet/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/920432470?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/Cardnet/favicon.ico',
    },
    allowIndicativeFallback: true,
  },
  'clover.uk': {
    id: 'Clover',
    displayName: 'Clover',
    theme: {
      primary: '22 88 00',
      secondary: '26 26 26',
    },
    images: {
      logo: 'Clover/logo.svg',
      heroImage: 'Clover/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/1021793472?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/Cardnet/favicon.ico',
      useCases1: 'Clover/growth.png',
      useCases2: 'Clover/cashflow.png',
      useCases3: 'Clover/brand.png',
      aboutLiberisCrossbrand: 'Clover/aboutLiberisCrossbrand.png',
    },
    paymentChannels: [
      {
        name: 'Clover',
        type: 'Card Processor',
        primary: true,
        existing_split: false,
      },
    ],
    activeCustomerSettings: {
      AUTH0_BASE_URL: 'https://clover.uk.liberis.com',
    },
    allowIndicativeFallback: true,
  },
  'createpay.uk': {
    id: 'CREATEPAY_GBR',
    displayName: 'CreatePay',
    theme: {
      primary: '0 0 0',
      secondary: '143 226 176',
    },
    images: {
      logo: 'CREATEPAY_GBR/logo.svg',
      heroImage: 'CREATEPAY_GBR/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/976446156?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      useCases1: 'CREATEPAY_GBR/growth.png',
      useCases2: 'CREATEPAY_GBR/cashflow.png',
      useCases3: 'CREATEPAY_GBR/brand.png',
      aboutLiberisCrossbrand: 'CREATEPAY_GBR/collabCreatePayGBR.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/CREATEPAY_GBR/favicon.ico',
    },
    paymentChannels: [
      {
        name: 'CREATEPAY_GBR',
        type: 'Card Processor',
        primary: true,
        existing_split: false,
      },
    ],
    allowIndicativeFallback: true,
    activeCustomerSettings: {
      AUTH0_BASE_URL: 'https://createpay.uk.liberis.com',
    },
  },
  dnapayments: {
    id: 'DNAPaymentsUK',
    displayName: 'DNA Payments',
    theme: {
      primary: '29 23 55',
      secondary: '253 214 38',
    },
    images: {
      logo: 'DNAPaymentsUK/logo.svg',
      heroImage: 'DNAPaymentsUK/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/903259420?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      useCases1: 'DNAPaymentsUK/growth.png',
      useCases2: 'DNAPaymentsUK/cashflow.png',
      useCases3: 'DNAPaymentsUK/brand.png',
      aboutLiberisCrossbrand: 'DNAPaymentsUK/aboutLiberisCrossbrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/DNAPaymentsUK/favicon.ico',
    },
    allowIndicativeFallback: true,
    disallowMIDinURL: true,
  },
  'easipay.uk': {
    id: 'EASIPAY_GBR',
    displayName: 'Easipay',
    theme: {
      primary: '58 190 238',
      secondary: '14 11 35',
      buttonTextColor: 'var(--core-8)',
      buttonTextColorHover: 'var(--core-8)',
      textOnPrimary: 'var(--core-8)',
      contrastTextOnPrimary: 'var(--core-8)',
      checkboxColour: 'var(--secondary)',
    },
    images: {
      logo: 'EASIPAY_GBR/logo.svg',
      heroImage: 'EASIPAY_GBR/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/1049683255?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      useCases1: 'EASIPAY_GBR/growth.png',
      useCases2: 'EASIPAY_GBR/cashflow.png',
      useCases3: 'EASIPAY_GBR/discover.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/EASIPAY_GBR/favicon.png',
    },
    allowIndicativeFallback: true,
    openBanking: true,
    paymentChannels: [
      {
        name: 'WorldPay',
        type: 'Card Processor',
        primary: true,
        existing_split: false,
      },
    ],
  },
  'ebay.us': {
    id: 'EBAY_USA',
    displayName: 'eBay',
    contactPhoneNumber: '+1 470 582 1130',
    theme: {
      primary: '65 100 234',
      secondary: '134 237 131',
      darkenedPrimary: '49 29 181',
      'error-1': '225 45 45',
    },
    images: {
      logo: 'EBAY_USA/logo.svg',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/EBAY_USA/favicon.ico',
    },
    businessSectorOptions: [
      { translationKey: 'industry_antiques', value: 'Antiques' },
      { translationKey: 'industry_books', value: 'Books' },
      {
        translationKey: 'industry_trade_contractor',
        value: 'Trade Contractor',
      },
      { translationKey: 'industry_fashion', value: 'Fashion Retail' },
      { translationKey: 'industry_collectibles', value: 'Collectibles' },
      {
        translationKey: 'industry_electronics',
        value: 'Consumer Electronics',
      },
      { translationKey: 'industry_crafts', value: 'Crafts' },
      { translationKey: 'industry_dolls', value: 'Dolls & Bears' },
      { translationKey: 'industry_garden', value: 'Home & Garden' },
      {
        translationKey: 'industry_car_sales',
        value: 'Car Sales / Garage Services',
      },
      {
        translationKey: 'industry_pets',
        value: 'Pet Supplies',
      },
      {
        translationKey: 'industry_sports_equipment',
        value: 'Sporting Goods',
      },
      {
        translationKey: 'industry_sports_fan',
        value: 'Sports Mem, Cards & Fan Shop',
      },
      { translationKey: 'industry_toys', value: 'Toys & Hobbies' },
      {
        translationKey: 'industry_computing',
        value: 'Computers/Tablets & Networking',
      },
    ],
    prepopulatedJourney: true,
    useLandingPage: false,
    openBanking: false,
    enableOpenBankingOverAmount: 0,
    country: 'US',
    // Ebay needs 'US' for KYC but everyone else is 'United States'
    countryName: 'US',
    profileReturnLink: 'https://accountsettings.ebay.com/profile',
    faqsLink: 'https://pages.ebay.com/ebaysellercapital/#FAQs',
    locale: 'en_US',
  },
  'elavon.pl': {
    id: 'ELAVON_POL',
    country: 'PL',
    displayName: 'Elavon Poland',
    theme: {
      primary: '12 32 116',
      secondary: '0 124 190',
    },
    images: {
      logo: 'ELAVON_POL/logo.svg',
      heroImage: 'ELAVON_POL/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/903290206?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      useCases1: 'ELAVON_POL/growth.png',
      useCases2: 'ELAVON_POL/cashflow.png',
      useCases3: 'ELAVON_POL/brand.png',
      aboutLiberisCrossbrand: 'ELAVON_POL/aboutLiberisCrossbrand.png',
    },
    indicativeQuoting: true,
    openBanking: true,
    disallowMIDinURL: true,
    locale: 'pl_PL',
  },
  'elavon.uk': {
    id: 'ElavonUK',
    displayName: 'Elavon UK',
    theme: {
      primary: '12 32 116',
      secondary: '0 124 190',
    },
    images: {
      logo: 'ElavonUK/logo.svg',
      heroImage: 'ElavonUK/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/903290206?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      useCases1: 'ElavonUK/growth.png',
      useCases2: 'ElavonUK/cashflow.png',
      useCases3: 'ElavonUK/brand.png',
      aboutLiberisCrossbrand: 'ElavonUK/aboutLiberisCrossbrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/ElavonUK/favicon.ico',
    },
    paymentChannels: [
      {
        name: 'Elavon',
        type: 'Card Processor',
        primary: true,
        existing_split: false,
      },
    ],
    allowIndicativeFallback: true,
    disallowMIDinURL: true,
  },
  'elavon.ie': {
    id: 'ElavonIreland',
    country: 'IE',
    displayName: 'Elavon Ireland',
    theme: {
      primary: '12 32 116',
      secondary: '0 124 190',
    },
    images: {
      logo: 'ElavonIreland/logo.svg',
      heroImage: 'ElavonIreland/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/1026951160?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      useCases1: 'ElavonIreland/growth.png',
      useCases2: 'ElavonIreland/cashflow.png',
      useCases3: 'ElavonIreland/brand.png',
      aboutLiberisCrossbrand: 'ElavonIreland/aboutLiberisCrossbrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/ElavonIreland/favicon.ico',
    },
    allowIndicativeFallback: true,
    disallowMIDinURL: true,
  },
  // US production partner agnostic URL
  'capital.us': {
    id: 'CapitalUS',
    displayName: 'Liberis',
    theme: {
      primary: '35 44 47',
      'core-8': '80 86 89',
    },
    images: {
      logo: 'default/logo.svg',
    },
    country: 'US',
    countryName: 'US',
    isPartnerUnknown: true,
    activeCustomerSettings: {
      AUTH0_BASE_URL: 'https://capital.us.liberis.com',
    },
    locale: 'en_US',
    activeCustomerOnly: true,
  },
  // EU production partner agnostic URL
  capital: {
    id: 'Capital',
    displayName: 'Liberis',
    theme: {
      primary: '35 44 47',
      'core-8': '80 86 89',
    },
    images: {
      logo: 'default/logo.svg',
    },
    country: 'GB',
    isPartnerUnknown: true,
    activeCustomerSettings: {
      AUTH0_BASE_URL: 'https://capital.liberis.com',
    },
    activeCustomerOnly: true,
  },
  fundingoptions: {
    id: 'FUNDINGOPTIONS_GBR',
    displayName: 'Funding Options',
    theme: {
      primary: '252 194 45',
      secondary: '25 41 214',
      buttonTextColor: 'var(--core-8)',
      buttonTextColorHover: 'var(--core-8)',
      textOnPrimary: 'var(--core-8)',
      contrastTextOnPrimary: 'var(--core-8)',
      checkboxColour: 'var(--secondary)',
    },
    images: {
      logo: 'FUNDINGOPTIONS_GBR/logo.svg',
      heroImage: 'FUNDINGOPTIONS_GBR/Funding-Options-Hero.png',
      useCases1: 'FUNDINGOPTIONS_GBR/growth.png',
      useCases2: 'FUNDINGOPTIONS_GBR/cashflow.png',
      useCases3: 'FUNDINGOPTIONS_GBR/brand.png',
      aboutLiberisCrossbrand: 'FUNDINGOPTIONS_GBR/aboutLiberisCrossbrand.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/872883298?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/FUNDINGOPTIONS_GBR/favicon.ico',
    },
    singlePageJourney: true,
    midFormatRegex:
      '^(?:[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}|\\d{4,7})$',
  },
  'payjet.de': {
    id: 'PAYJET_DEU',
    displayName: 'Pay-Jet ',
    theme: {
      primary: '88 80 236',
      secondary: '88 80 236',
    },
    images: {
      logo: 'PAYJET_DEU/logo.svg',
      aboutLiberisCrossbrand: 'PAYJET_DEU/aboutLiberisCrossbrand.png',
      heroImage: 'PAYJET_DEU/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/1052060661?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      useCases1: 'PAYJET_DEU/growth.png',
      useCases2: 'PAYJET_DEU/cashflow.png',
      useCases3: 'PAYJET_DEU/discover.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/PAYJET_DEU/favicon.jpeg',
    },
    country: 'DE',
    allowIndicativeFallback: true,
    locale: 'de_DE',
  },
  'nuvei.ca': {
    id: 'NUVEI_CAN',
    displayName: 'Nuvei Canada',
    country: 'CA',
    theme: {
      primary: '228 0 70',
      secondary: '14 11 35',
    },
    images: {
      logo: 'NUVEI_CAN/logo.svg',
      heroImage: 'NUVEI_CAN/Nuvei-Hero.png',
      useCases1: 'NUVEI_CAN/growth.png',
      useCases2: 'NUVEI_CAN/cashflow.png',
      useCases3: 'NUVEI_CAN/brand.png',
      aboutLiberisCrossbrand: 'NUVEI_CAN/aboutLiberisCrossbrand.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/917183972?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/NUVEI_CAN/favicon.ico',
    },
    midFormatRegex:
      '^(?:[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}|\\d{4,7})$',
    allowIndicativeFallback: true,
    languageLocale: ['en-CA', 'fr-CA'],
    applicationDisclosure: true,
    locale: 'en_CA',
    encore: true,
  },
  'nuvei.us': {
    id: 'NUVEI_USA',
    displayName: 'Nuvei USA',
    country: 'US',
    theme: {
      primary: '228 0 70',
      secondary: '14 11 35',
    },
    images: {
      logo: 'NUVEI_USA/logo.svg',
      heroImage: 'NUVEI_USA/Nuvei-Hero.png',
      useCases1: 'NUVEI_USA/growth.png',
      useCases2: 'NUVEI_USA/cashflow.png',
      useCases3: 'NUVEI_USA/brand.png',
      aboutLiberisCrossbrand: 'NUVEI_USA/aboutLiberisCrossbrand.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/917183972?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/NUVEI_USA/favicon.ico',
    },
    midFormatRegex:
      '^(?:[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}|\\d{4,7})$',
    allowIndicativeFallback: true,
    locale: 'en_US',
    encore: true,
    openBanking: true,
  },
  'nets.fi': {
    id: 'NetsFinland',
    displayName: 'Nets Finland',
    country: 'FI',
    theme: {
      primary: '45 50 170',
      secondary: '51 195 177',
    },
    images: {
      logo: 'NetsFinland/logo.svg',
      heroImage: 'NetsFinland/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/914579496?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      useCases1: 'NetsFinland/growth.png',
      useCases2: 'NetsFinland/cashflow.png',
      useCases3: 'NetsFinland/brand.png',
      aboutLiberisCrossbrand: 'NetsFinland/aboutLiberisCrossbrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/NetsFinland/favicon.ico',
    },
    paymentChannels: [
      {
        name: 'NETS Finland',
        type: 'Card Processor',
        primary: true,
        existing_split: false,
      },
    ],
    languageLocale: ['fi-FI', 'en-GB'],
    locale: 'fi_FI',
  },
  'nexi.de': {
    id: 'NEXI_DEU',
    displayName: 'NEXI',
    theme: {
      primary: '45 50 170',
      secondary: '51 195 177',
    },
    images: {
      logo: 'NEXI_DEU/logo.svg',
      heroImage: 'NEXI_DEU/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/976416848?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&muted=1',
      useCases1: 'NEXI_DEU/growth.png',
      useCases2: 'NEXI_DEU/cashflow.png',
      useCases3: 'NEXI_DEU/brand.png',
      aboutLiberisCrossbrand: 'NEXI_DEU/collabNexi.png',
    },
    country: 'DE',
    allowIndicativeFallback: true,
    locale: 'de_DE',
  },
  rapyd: {
    id: 'RAPYD_GBR',
    displayName: 'Rapyd',
    theme: {
      primary: '100 55 226',
      secondary: '255 0 122',
    },
    images: {
      logo: 'RAPYD_GBR/logo.svg',
      heroImage: 'RAPYD_GBR/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/903287928?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&muted=1',
      useCases1: 'RAPYD_GBR/growth.png',
      useCases2: 'RAPYD_GBR/cashflow.png',
      useCases3: 'RAPYD_GBR/brand.png',
      aboutLiberisCrossbrand: 'RAPYD_GBR/aboutLiberisCrossbrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/RAPYD_GBR/favicon.ico',
    },
    allowIndicativeFallback: true,
  },
  rms: {
    id: 'SaltPayRMSUK',
    displayName: 'RMS',
    theme: {
      primary: '35 44 47',
      'core-8': '80 86 89',
    },
    images: {
      logo: 'SaltPayRMSUK/logo.svg',
    },
    singlePageJourney: true,
    logoALTText: 'Liberis',
    contactPhoneNumber: '442033717797',
    paymentChannels: [
      {
        name: 'TEYA_GBR',
        type: 'Card Processor',
        primary: true,
        existing_split: false,
      },
    ],
  },
  saltpayrms: {
    id: 'SaltPayRMSUK',
    displayName: 'SaltPay',
    theme: {
      primary: '35 44 47',
      'core-8': '80 86 89',
    },
    images: {
      logo: 'SaltPayRMSUK/logo.svg',
    },
    singlePageJourney: true,
    logoALTText: 'Liberis',
    contactPhoneNumber: '442033717797',
    paymentChannels: [
      {
        name: 'TEYA_GBR',
        type: 'Card Processor',
        primary: true,
        existing_split: false,
      },
    ],
  },
  'sezzle.us': {
    id: 'SEZZLE_USA',
    displayName: 'Sezzle',
    country: 'US',
    theme: {
      primary: '130 51 212',
      secondary: '41 212 162',
    },
    images: {
      logo: 'SEZZLE_USA/logo.svg',
      heroImage: 'SEZZLE_USA/hero.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/976567768?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0',
      useCases1: 'SEZZLE_USA/growth.png',
      useCases2: 'SEZZLE_USA/cashflow.png',
      useCases3: 'SEZZLE_USA/brand.png',
      aboutLiberisCrossbrand: 'SEZZLE_USA/aboutLiberisCrossbrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/SEZZLE_USA/favicon.png',
    },
    indicativeQuoting: true,
    openBanking: true,
    activeCustomerSettings: {
      AUTH0_BASE_URL: 'https://sezzle.us.liberis.com',
    },
    locale: 'en_US',
  },
  'shopcircle.ca': {
    id: 'SHOPCIRCLE_CAN',
    displayName: 'Shop Circle',
    country: 'CA',
    theme: {
      primary: '54 105 87',
      secondary: '229 238 234',
    },
    images: {
      logo: 'SHOPCIRCLE_CAN/logo.svg',
      heroImage: 'SHOPCIRCLE_CAN/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/913506273?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0',
      useCases1: 'SHOPCIRCLE_CAN/growth.png',
      useCases2: 'SHOPCIRCLE_CAN/cashflow.png',
      useCases3: 'SHOPCIRCLE_CAN/brand.png',
      aboutLiberisCrossbrand: 'SHOPCIRCLE_CAN/aboutLiberisCrossbrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/SHOPCIRCLE_CAN/favicon.ico',
    },
    contactPhoneNumber: '+1 647 660 4945',
    indicativeQuoting: true,
    showBD1Warning: true,
    openBanking: true,
    locale: 'en_CA',
  },
  'shopcircle.uk': {
    id: 'SHOPCIRCLE_GBR',
    displayName: 'Shop Circle',
    country: 'GB',
    theme: {
      primary: '54 105 87',
      secondary: '229 238 234',
    },
    images: {
      logo: 'SHOPCIRCLE_GBR/logo.svg',
      heroImage: 'SHOPCIRCLE_GBR/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/914704039?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0',
      useCases1: 'SHOPCIRCLE_GBR/growth.png',
      useCases2: 'SHOPCIRCLE_GBR/cashflow.png',
      useCases3: 'SHOPCIRCLE_GBR/brand.png',
      aboutLiberisCrossbrand: 'SHOPCIRCLE_GBR/aboutLiberisCrossbrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/SHOPCIRCLE_GBR/favicon.ico',
    },
    contactPhoneNumber: '+44 8000 668 995',
    indicativeQuoting: true,
    openBanking: true,
    googleAnalyticsId: 'G-8DL73DRR75',
  },
  'shopcircle.us': {
    id: 'SHOPCIRCLE_USA',
    displayName: 'Shop Circle',
    country: 'US',
    theme: {
      primary: '54 105 87',
      secondary: '229 238 234',
    },
    images: {
      logo: 'SHOPCIRCLE_USA/logo.svg',
      heroImage: 'SHOPCIRCLE_USA/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/913506273?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0',
      useCases1: 'SHOPCIRCLE_USA/growth.png',
      useCases2: 'SHOPCIRCLE_USA/cashflow.png',
      useCases3: 'SHOPCIRCLE_USA/brand.png',
      aboutLiberisCrossbrand: 'SHOPCIRCLE_USA/aboutLiberisCrossbrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/SHOPCIRCLE_USA/favicon.ico',
    },
    indicativeQuoting: true,
    openBanking: true,
    googleAnalyticsId: 'G-NTXBNG21YN',
    locale: 'en_US',
    languageLocale: ['en-US'],
  },
  takepayments: {
    id: 'TAKEPAYMENTS_GBR',
    displayName: 'Take Payments',
    theme: {
      primary: '178 11 99',
      secondary: '22 16 49',
    },
    images: {
      logo: 'TAKEPAYMENTS_GBR/logo.svg',
      heroImage: 'TAKEPAYMENTS_GBR/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/891796331?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      useCases1: 'TAKEPAYMENTS_GBR/growth.png',
      useCases2: 'TAKEPAYMENTS_GBR/cashflow.png',
      aboutLiberisCrossbrand: 'TAKEPAYMENTS_GBR/aboutLiberisCrossbrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/TAKEPAYMENTS_GBR/favicon.ico',
    },
    allowIndicativeFallback: true,
    inJourneyRenewalsEnabled: false,
  },
  teya: {
    id: 'TEYA_GBR',
    displayName: 'Teya',
    theme: {
      primary: '221 224 72',
      secondary: '21 21 21',
      buttonTextColor: 'var(--secondary)',
      buttonTextColorHover: 'var(--secondary)',
      textOnPrimary: 'var(--secondary)',
      contrastTextOnPrimary: 'var(--secondary)',
      checkboxColour: 'var(--secondary)',
    },
    images: {
      logo: 'TEYA_GBR/logo.svg',
      heroImage: 'TEYA_GBR/TeyaHero.png',
      useCases1: 'TEYA_GBR/growth.png',
      useCases2: 'TEYA_GBR/cashflow.png',
      useCases3: 'TEYA_GBR/brand.png',
      aboutLiberisCrossbrand: 'TEYA_GBR/collabTeya.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/886414596?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/TEYA_GBR/favicon.ico',
    },
    singlePageJourney: true,
  },
  tide: {
    id: 'Tide',
    displayName: 'Tide',
    theme: {
      primary: '64 80 251',
      secondary: '211 45 125',
      checkboxColour: 'var(--secondary)',
    },
    images: {
      logo: 'Tide/logo.svg',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/tide/favicon.ico',
    },
    contactPhoneNumber: '443301081284',
    singlePageJourney: true,
    midFormatRegex:
      '^(?:[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}|\\d{4,7})$',
  },
  'viva.fi': {
    id: 'VIVA_FIN',
    displayName: 'Viva Wallet',
    country: 'FI',
    theme: {
      primary: '31 38 58',
      secondary: '50 193 213',
    },
    images: {
      logo: 'VIVA_FIN/logo.svg',
      heroImage: 'VIVA_FIN/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/914579496?byline=0&controls=1&fun=0&muted=1&portrait=0&title=0&loop=1&autopause=0&amp;muted=1',
      useCases1: 'VIVA_FIN/growth.png',
      useCases2: 'VIVA_FIN/cashflow.png',
      useCases3: 'VIVA_FIN/brand.png',
      aboutLiberisCrossbrand: 'VIVA_FIN/aboutLiberisCrossbrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/VIVA_FIN/favicon.ico',
    },
    contactPhoneNumber: '+358 94 245 2348',
    allowIndicativeFallback: true,
    locale: 'fi_FI',
  },
  'worldpay.uk': {
    id: 'Worldpay',
    displayName: 'Worldpay',
    country: 'GB',
    theme: {
      primary: '255 31 62',
    },
    images: {
      logo: 'Worldpay/logo.svg',
      heroImage: 'Worldpay/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/1006503328?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      useCases1: 'Worldpay/growth.png',
      useCases2: 'Worldpay/cashflow.png',
      useCases3: 'Worldpay/brand.png',
      aboutLiberisCrossbrand: 'Worldpay/cobrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/Worldpay/favicon.ico',
    },
    allowIndicativeFallback: true,
    paymentChannels: [
      {
        name: 'WorldPay',
        type: 'Card Processor',
        primary: true,
        existing_split: false,
      },
    ],
  },
  'worldpay.us': {
    id: 'WorldpayUS',
    displayName: 'Worldpay',
    country: 'US',
    theme: {
      primary: '255 31 62',
    },
    images: {
      logo: 'Worldpay/logo.svg',
      heroImage: 'Worldpay/heroImage.png',
      'explainer-video-url':
        'https://player.vimeo.com/video/1035165014?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      useCases1: 'Worldpay/growth.png',
      useCases2: 'Worldpay/cashflow.png',
      useCases3: 'Worldpay/brand.png',
      aboutLiberisCrossbrand: 'Worldpay/cobrand.png',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/Worldpay/favicon.ico',
    },
    allowIndicativeFallback: true,
    locale: 'en_US',
    paymentChannels: [
      {
        name: 'Worldpay US',
        type: 'Card Processor',
        primary: true,
        existing_split: false,
      },
    ],
    encore: true,
  },
  mockpartneruk: {
    id: 'LiberisSandbox',
    displayName: 'Example Partner',
    theme: {
      primary: '34 136 0',
      secondary: '134 237 131',
      textOnPrimary: '255 255 255',
    },
    images: {
      logo: 'LiberisSandbox/logo.svg',
    },
    devOnly: true,
  },
  mockpartnerus: {
    id: 'LiberisSandbox',
    displayName: 'Mock Business',
    country: 'US',
    theme: {
      primary: '0 0 0',
      secondary: '125 125 125',
    },
    images: {
      logo: 'LiberisSandbox/logo.svg',
    },
    devOnly: true,
  },
  mockpartnersinglepage: {
    id: 'mockpartnersinglepage',
    displayName: 'MPSP',
    theme: {},
    images: {
      logo: 'LiberisSandbox/logo.svg',
    },
    singlePageJourney: true,
    logoALTText: 'Liberis',
  },
  demo: {
    id: 'LiberisSandbox',
    displayName: 'Demo Partner',
    theme: {
      primary: '34 136 0',
      secondary: '134 237 131',
    },
    images: {
      logo: 'LiberisSandbox/logo.svg',
    },
    devOnly: true,
    allowIndicativeFallback: true,
  },
  'sandbox.core': {
    id: 'LiberisSandbox',
    displayName: 'Sandbox MX Journey',
    theme: {
      primary: '34 136 0',
      secondary: '134 237 131',
    },
    images: {
      logo: 'LiberisSandbox/logo.svg',
    },
    devOnly: true,
    allowIndicativeFallback: true,
    languageLocale: ['en-GB', 'fr-CA', 'fi-FI'],
  },
  'ebay.demo': {
    id: 'LiberisSandbox',
    displayName: 'eBay Demo',
    contactPhoneNumber: '+1 470 582 1130',
    theme: {
      primary: '65 100 234',
      secondary: '134 237 131',
      darkenedPrimary: '49 29 181',
      'error-1': '235 62 63',
    },
    images: {
      logo: 'EBAY_USA/logo.svg',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/EBAY_USA/favicon.ico',
    },
    businessTypeOptions: [
      {
        translationKey: 'business_type_sole_proprietor',
        value: 'Sole Proprietor',
      },
      { translationKey: 'business_type_partnership', value: 'Partnership' },
      {
        translationKey: 'business_type_limited_partnership',
        value: 'Limited Partnership',
      },
      {
        translationKey: 'business_type_limited_company',
        value: 'Limited Company',
      },
      {
        translationKey: 'business_type_llc',
        value: 'LLC',
      },
      {
        translationKey: 'business_type_other',
        value: 'Other Corporation',
      },
    ],
    businessSectorOptions: [
      { translationKey: 'industry_antiques', value: 'Antiques' },
      { translationKey: 'industry_books', value: 'Books' },
      {
        translationKey: 'industry_trade_contractor',
        value: 'Trade Contractor',
      },
      { translationKey: 'industry_fashion', value: 'Fashion Retail' },
      { translationKey: 'industry_collectibles', value: 'Collectibles' },
      {
        translationKey: 'industry_electronics',
        value: 'Consumer Electronics',
      },
      { translationKey: 'industry_crafts', value: 'Crafts' },
      { translationKey: 'industry_dolls', value: 'Dolls & Bears' },
      { translationKey: 'industry_garden', value: 'Home & Garden' },
      {
        translationKey: 'industry_car_sales',
        value: 'Car Sales / Garage Services',
      },
      {
        translationKey: 'industry_pets',
        value: 'Pet Supplies',
      },
      {
        translationKey: 'industry_sports_equipment',
        value: 'Sporting Goods',
      },
      {
        translationKey: 'industry_sports_fan',
        value: 'Sports Mem, Cards & Fan Shop',
      },
      { translationKey: 'industry_toys', value: 'Toys & Hobbies' },
      {
        translationKey: 'industry_computing',
        value: 'Computers/Tablets & Networking',
      },
    ],
    prepopulatedJourney: true,
    useLandingPage: false,
    country: 'US',
    // Ebay needs 'US' for KYC but everyone else is 'United States'
    countryName: 'US',
    profileReturnLink: 'https://accountsettings.ebay.com/profile',
    faqsLink: 'https://pages.ebay.com/ebaysellercapital/#FAQs',
    locale: 'en_US',
  },
  'ebay.next': {
    id: 'LiberisSandbox',
    displayName: 'Core Test',
    contactPhoneNumber: '+1 470 582 1130',
    theme: {
      primary: '65 100 234',
      secondary: '134 237 131',
      darkenedPrimary: '49 29 181',
      'error-1': '225 45 45',
    },
    images: {
      logo: 'EBAY_USA/logo.svg',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/EBAY_USA/favicon.ico',
    },
    businessTypeOptions: [
      {
        translationKey: 'business_type_sole_proprietor',
        value: 'Sole Proprietor',
      },
      { translationKey: 'business_type_partnership', value: 'Partnership' },
      {
        translationKey: 'business_type_limited_partnership',
        value: 'Limited Partnership',
      },
      {
        translationKey: 'business_type_limited_company',
        value: 'Limited Company',
      },
      {
        translationKey: 'business_type_llc',
        value: 'LLC',
      },
      {
        translationKey: 'business_type_other',
        value: 'Other Corporation',
      },
    ],
    businessSectorOptions: [
      { translationKey: 'industry_antiques', value: 'Antiques' },
      { translationKey: 'industry_books', value: 'Books' },
      {
        translationKey: 'industry_trade_contractor',
        value: 'Trade Contractor',
      },
      { translationKey: 'industry_fashion', value: 'Fashion Retail' },
      { translationKey: 'industry_collectibles', value: 'Collectibles' },
      {
        translationKey: 'industry_electronics',
        value: 'Consumer Electronics',
      },
      { translationKey: 'industry_crafts', value: 'Crafts' },
      { translationKey: 'industry_dolls', value: 'Dolls & Bears' },
      { translationKey: 'industry_garden', value: 'Home & Garden' },
      {
        translationKey: 'industry_car_sales',
        value: 'Car Sales / Garage Services',
      },
      {
        translationKey: 'industry_pets',
        value: 'Pet Supplies',
      },
      {
        translationKey: 'industry_sports_equipment',
        value: 'Sporting Goods',
      },
      {
        translationKey: 'industry_sports_fan',
        value: 'Sports Mem, Cards & Fan Shop',
      },
      { translationKey: 'industry_toys', value: 'Toys & Hobbies' },
      {
        translationKey: 'industry_computing',
        value: 'Computers/Tablets & Networking',
      },
    ],
    prepopulatedJourney: true,
    useLandingPage: false,
    openBanking: true,
    enableOpenBankingOverAmount: 0,
    country: 'US',
    // Ebay needs 'US' for KYC but everyone else is 'United States'
    countryName: 'US',
    locale: 'en_US',
    profileReturnLink: 'https://accountsettings.ebay.com/profile',
    faqsLink: 'https://pages.ebay.com/ebaysellercapital/#FAQs',
    activeCustomerSettings: {
      AUTH0_ISSUER_BASE_URL: 'https://dev-liberis-external.us.auth0.com',
      AUTH0_CLIENT_ID: 'ReJgTHcupasnJeNVcNdCDVIsfNqsVofN',
      AUTH0_BASE_URL:
        process.env.NODE_ENV === 'development'
          ? 'http://ebay.next.localhost:3000'
          : 'https://ebay.next.liberis.com',
    },
  },
  // Dev partner agnostic URL
  defaultpartner: {
    id: 'LiberisSandbox',
    displayName: 'Core Test',
    contactPhoneNumber: '+1 470 582 1130',
    theme: {
      primary: '35 44 47',
      'core-8': '80 86 89',
    },
    images: {
      logo: 'LiberisSandbox/logo.svg',
    },
    country: 'US',
    countryName: 'US',
    locale: 'en_US',
    isPartnerUnknown: true,
    activeCustomerSettings: {
      AUTH0_ISSUER_BASE_URL: 'https://dev-liberis-external.us.auth0.com',
      AUTH0_CLIENT_ID: 'ReJgTHcupasnJeNVcNdCDVIsfNqsVofN',
      AUTH0_BASE_URL:
        process.env.NODE_ENV === 'development'
          ? 'http://defaultpartner.localhost:3000'
          : 'https://defaultpartner.liberis.com',
    },
  },
  'core-test': {
    id: 'LiberisSandbox',
    displayName: 'Core Test',
    theme: {
      primary: '34 136 0',
      secondary: '134 237 131',
    },
    images: {
      logo: 'LiberisSandbox/logo.svg',
    },
    devOnly: true,
    languageLocale: ['en-GB', 'fr-CA', 'fi-FI'],
    allowIndicativeFallback: true,
    prepopulatedJourney: false,
    country: 'US',
    activeCustomerSettings: {
      AUTH0_ISSUER_BASE_URL: 'https://dev-liberis-external.us.auth0.com',
      AUTH0_CLIENT_ID: 'ReJgTHcupasnJeNVcNdCDVIsfNqsVofN',
      AUTH0_BASE_URL:
        process.env.NODE_ENV === 'development'
          ? 'http://core-test.localhost:3000'
          : 'https://core-test.liberis.com',
    },
  },
  'demo-de': {
    id: 'LiberisSandbox',
    displayName: 'Demo Partner',
    country: 'DE',
    theme: {
      primary: '34 136 0',
      secondary: '134 237 131',
    },
    images: {
      logo: 'LiberisSandbox/logo.svg',
    },
    devOnly: true,
    languageLocale: ['de-DE'],
    locale: 'de_DE',
  },
  'demo-fr': {
    id: 'LiberisSandbox',
    displayName: 'Demo Partner',
    currency: 'CAD',
    currencySymbol: '$',
    locale: 'fr_CA',
    theme: {
      primary: '34 136 0',
      secondary: '134 237 131',
    },
    images: {
      logo: 'LiberisSandbox/logo.svg',
    },
    devOnly: true,
    allowIndicativeFallback: true,
  },
  'demo-pl': {
    id: 'LiberisSandbox',
    displayName: 'Demo Partner',
    country: 'PL',
    theme: {
      primary: '34 136 0',
      secondary: '134 237 131',
    },
    images: {
      logo: 'LiberisSandbox/logo.svg',
    },
    postcodeRegex:
      '^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$',
    devOnly: true,
    indicativeQuoting: true,
    openBanking: true,
    locale: 'pl_PL',
  },
  'ob-demo': {
    id: 'LiberisSandbox',
    displayName: 'Open Banking Partner',
    country: 'GB',
    theme: {
      primary: '34 136 0',
      secondary: '134 237 131',
    },
    images: {
      logo: 'LiberisSandbox/logo.svg',
    },
    indicativeQuoting: true,
    openBanking: true,
  },
  'ltpartner.us': {
    id: 'LTPARTNER_USA',
    displayName: 'Core Test',
    contactPhoneNumber: '+1 470 582 1130',
    theme: {
      primary: '65 100 234',
      secondary: '134 237 131',
      darkenedPrimary: '49 29 181',
      'error-1': '225 45 45',
    },
    images: {
      logo: 'EBAY_USA/logo.svg',
      favicon:
        'https://liberisproductioncdn.blob.core.windows.net/images/liberisCore/EBAY_USA/favicon.ico',
    },
    businessTypeOptions: [
      {
        translationKey: 'business_type_sole_proprietor',
        value: 'Sole Proprietor',
      },
      { translationKey: 'business_type_partnership', value: 'Partnership' },
      {
        translationKey: 'business_type_limited_partnership',
        value: 'Limited Partnership',
      },
      {
        translationKey: 'business_type_limited_company',
        value: 'Limited Company',
      },
      {
        translationKey: 'business_type_llc',
        value: 'LLC',
      },
      {
        translationKey: 'business_type_other',
        value: 'Other Corporation',
      },
    ],
    businessSectorOptions: [
      { translationKey: 'industry_antiques', value: 'Antiques' },
      { translationKey: 'industry_books', value: 'Books' },
      {
        translationKey: 'industry_trade_contractor',
        value: 'Trade Contractor',
      },
      { translationKey: 'industry_fashion', value: 'Fashion Retail' },
      { translationKey: 'industry_collectibles', value: 'Collectibles' },
      {
        translationKey: 'industry_electronics',
        value: 'Consumer Electronics',
      },
      { translationKey: 'industry_crafts', value: 'Crafts' },
      { translationKey: 'industry_dolls', value: 'Dolls & Bears' },
      { translationKey: 'industry_garden', value: 'Home & Garden' },
      {
        translationKey: 'industry_car_sales',
        value: 'Car Sales / Garage Services',
      },
      {
        translationKey: 'industry_pets',
        value: 'Pet Supplies',
      },
      {
        translationKey: 'industry_sports_equipment',
        value: 'Sporting Goods',
      },
      {
        translationKey: 'industry_sports_fan',
        value: 'Sports Mem, Cards & Fan Shop',
      },
      { translationKey: 'industry_toys', value: 'Toys & Hobbies' },
      {
        translationKey: 'industry_computing',
        value: 'Computers/Tablets & Networking',
      },
    ],
    prepopulatedJourney: true,
    useLandingPage: false,
    country: 'US',
    // Ebay needs 'US' for KYC but everyone else is 'United States'
    countryName: 'US',
    profileReturnLink: 'https://accountsettings.ebay.com/profile',
    faqsLink: 'https://pages.ebay.com/ebaysellercapital/#FAQs',
    locale: 'en_US',
  },
  encore: {
    id: 'LiberisSandbox',
    displayName: 'Encore',
    theme: {},
    encore: true,
    useLandingPage: true,
    country: 'CA',
    countryName: 'US', // I think sandbox is wrong here
    openBanking: true,
    enableOpenBankingOverAmount: 0,
    languageLocale: ['en-CA', 'fr-CA'],
  },
};

export default partnerSettings;
